<template>
  <div>
    <app-bar currentPage="main"></app-bar>
    <div :style="{ height: `${windowHeight - 90}px` }">
      <loading-circle v-if="$store.getters['main/isLoading']"></loading-circle>
      <v-virtual-scroll
        v-else
        :bench="2"
        style="max-width: 630px"
        :item-height="isMobile ? '115px' : '145px'"
        :class="['mx-auto', `${isMobile ? 'noScroll' : ''}`, 'scroll']"
        :items="games"
      >
        <template v-slot:default="{ item }">
          <game-card :game="item" :key="item._id"></game-card
        ></template>
      </v-virtual-scroll>
      <img
        @click.stop="toNews"
        :src="require('@/assets/newsletter.svg')"
        :height="displaySize(70, 0.1)"
        class="nws shadowCard"
        :class="[isMobile ? 'onClick' : 'primPlan']"
      />
      <media-fab></media-fab>
      <entry-dialog></entry-dialog>
    </div>
  </div>
</template>

<script>
import LoadingCircle from "@/components/LoadingCircle.vue";
import GameCard from "../components/GameCard.vue";
import MediaFab from "../components/MediaFab.vue";
import EntryDialog from "../components/EntryDialog.vue";
import AppBar from "@/components/AppBar.vue";

export default {
  components: { LoadingCircle, GameCard, MediaFab, EntryDialog, AppBar },
  computed: {
    games() {
      return this.$store.getters["main/games"];
    },
  },
  beforeCreate() {
    this.$store.commit("currentPage", { page: "main" });
    //if (!this.$store.getters["main/games"].length)
    this.$store.dispatch({ type: "main/fetchGames" });
  },
  methods: {
    toNews() {
      this.$router.push({ name: "news" });
    },
  },
};
</script>

<style scoped>
.nws {
  position: fixed;
  bottom: 6.5%;
  right: 5%;
  cursor: pointer;
  z-index: 11;
}
</style>
