<template>
  <div>
    <v-card
      v-if="game.status === 2"
      color="#303030"
      class="d-flex align-center mt-7 gameCard"
      :class="[`${isMobile ? 'mx-2' : 'mx-5'}`]"
      :ripple="false"
    >
      <v-img
        style="border-radius: 22px"
        :height="isMobile ? '97px' : '119px'"
        width="100%"
        :src="require('@/assets/ComingSoonCuBg.svg')"
      />
    </v-card>
    <v-card
      v-else
      @click="toGame()"
      color="#303030"
      class="d-flex align-center mt-7 px-2 gameCard"
      :class="[`${isMobile ? 'mx-2 cardOnClick' : 'mx-5 cardOnHover'}`]"
      :ripple="false"
    >
      <v-avatar
        v-if="!game.status"
        id="solvedImg"
        :height="`${displaySize(80, 0.05)}px`"
        :width="`${displaySize(95, 0.05)}px`"
        tile
      >
        <v-img :src="require('@/assets/panglicaSolved.svg')"></v-img>
      </v-avatar>
      <v-col cols="2" class="">
        <v-avatar
          :class="[`${isMobile ? 'my-1' : ''}`]"
          :size="isMobile ? '65px' : '95px'"
          tile
        >
          <v-img :src="game.avatarImage"></v-img>
        </v-avatar>
      </v-col>
      <v-col cols="8" class="d-flex flex-column align-center pl-6">
        <p
          class="mTitleGame mb-0"
          :style="{ fontSize: `${isMobile ? '1' : '1.4'}em` }"
        >
          {{ game.title }}
        </p>
        <hr :style="{ height: '2px' }" class="breakline mb-2" />
        <p
          class="ma-auto bookman text-truncate"
          :style="{ fontSize: `${isMobile ? '0.75' : '1.1'}em` }"
        >
          Winner's Prize:
          {{ formatMoney(game.maxPay * game.multiply) }}€
        </p>
        <p
          class="ma-auto bookman date"
          :style="{ fontSize: `${isMobile ? '0.65' : '0.9'}em` }"
        >
          {{ getDate(game.createdAt).split(",")[0] }} -
          {{
            !game.status
              ? `${getDate(game.winner.date).split(",")[0]}`
              : "UNSOLVED"
          }}
        </p>
      </v-col>
      <v-col cols="2" :class="[`${isMobile ? 'pa-1' : ''}`]">
        <h1
          v-if="game.status"
          class="font-weight-black multiply bookman d-inline-block"
          :style="{ fontSize: `${isMobile ? '0.85' : '1.2'}em` }"
        >
          Live
        </h1>
      </v-col>
      <v-overlay color="black" opacity="0.65" absolute v-if="!game.status">
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["game"],

  methods: {
    toGame() {
      const { status, _id: id } = this.game;
      this.$store.state.main.currentGame = this.game;
      if (status !== 2)
        this.$store.dispatch({ type: "main/fetchGame", id, navigateTo: 1 });
    },
    formatMoney(n) {
      return (Math.round(n * 100) / 100).toLocaleString();
    },
  },
};
</script>

<style scoped>
.gameCard {
  border-radius: 22px;
  z-index: 10;
  box-shadow: 0px 4px 4px #00000040;
  transition: all 0.1s ease-in-out;
}
.cardOnHover:hover {
  filter: drop-shadow(0 0 0.5rem #c8c8c8);
  transform: scale(1.01);
}
.cardOnClick:active {
  filter: drop-shadow(0 0 0.5rem #c8c8c8);
  transform: scale(1.01);
}

.bookman {
  font-family: "Bookman Old Style";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.075em;
  color: #03f01a;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.multiply {
  letter-spacing: 0.075em;
  background: linear-gradient(
    180deg,
    rgba(0, 255, 25, 0.7) 45.94%,
    rgba(1, 68, 8, 0.7) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(0.83, -0.73, 0.46, 0.8, 0, 0);
}
.date {
  color: #9c9c9c;
  font-weight: 600;
}

.comingSoon {
  background: linear-gradient(
    0deg,
    rgba(109.44, 109.44, 109.44, 0.86) 0%,
    rgba(23.38, 23.38, 23.38, 1) 98%
  );
}

#solvedImg {
  position: absolute;
  top: 0%;
  left: 0;
  z-index: 10;
}
</style>
