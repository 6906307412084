<template>
  <div
    :class="[
      'social',
      open ? 'opened' : `${isMobile ? 'onClick' : 'primPlan'}`,
      'shadowCard',
    ]"
    @click.stop="toggle()"
  >
    <img
      :src="require('@/assets/SocialMedia.png')"
      :height="displaySize(80, 0.1)"
      :width="displaySize(80, 0.1)"
    />
    <v-overlay style="position: absolute" color="transparent">
      <img
        :src="require('@/assets/SocialMediaMask.png')"
        :height="displaySize(80, 0.1)"
        :width="displaySize(80, 0.1)"
      />
    </v-overlay>

    <img
      v-for="icon in socialMedia"
      :key="icon.id"
      :src="icon.src"
      :height="displaySize(22, 0.1)"
      :width="displaySize(22, 0.1)"
      :id="icon.id"
      :style="icon.style"
      @click="go_socialMedia(icon.link)"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    open: false,
    socialMedia: [
      {
        link: "https://www.tiktok.com/@masterycase",
        src: "https://i.ibb.co/CPwVv4x/tiktok-logo.png",
        id: "tiktok",
        class: "",
        style: "",
        trX: -3,
        trY: -220,
      },
      {
        link: "https://twitter.com/masterycase",
        src: "https://i.ibb.co/yYsYFJL/Twitter-logo.png",
        id: "twitter",
        style: "",
        trX: 15,
        trY: -180,
      },
      {
        link: "https://www.youtube.com/channel/UCwzkMVLEiiRvYlZpWwFianA",
        src: "https://i.ibb.co/P4RNLwt/youtube-logo-v2.png",
        id: "youtube",
        style: "",
        trX: -18,
        trY: -130,
      },
      {
        link: "https://www.facebook.com/MasteryCaseGame/",
        src: "https://i.ibb.co/X565Hmc/facebook-logo.png",
        id: "facebook",
        style: "",
        trX: -11,
        trY: -40,
      },
      {
        link: "https://www.instagram.com/masterycase/",
        src: "https://i.ibb.co/Bz0tDPL/Instagram-Logo.png",
        id: "instagram",
        style: "",
        trX: 7,
        trY: -90,
      },
    ],
  }),
  methods: {
    toggle() {
      this.open = !this.open;
      if (this.open)
        for (const social of this.socialMedia)
          social.style = {
            transform: `translate(${this.displaySize(
              social.trX,
              0.1
            )}px, ${this.displaySize(social.trY, 0.1)}px) scale(1.6)`,
          };
      else for (const social of this.socialMedia) social.style = {};
    },
    go_socialMedia(url) {
      if (this.open) window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.opened {
  filter: drop-shadow(0 0 0.4rem #c8c8c8);
  transform: scale(1.1);
}
.social {
  position: fixed;
  bottom: 5%;
  left: 5%;
  cursor: pointer;
  z-index: 11;
}
#instagram {
  position: absolute;
  bottom: 50%;
  left: 27%;
  transition: transform 1s;
}
#twitter {
  position: absolute;
  bottom: 65%;
  left: 16%;
  transition: transform 1s;
}
#youtube {
  position: absolute;
  bottom: 65%;
  right: 15%;
  transition: transform 1s;
}
#facebook {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transition: transform 1s;
}
#tiktok {
  position: absolute;
  bottom: 75%;
  left: 38%;
  transition: transform 1s;
}
</style>
