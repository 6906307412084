<template>
  <v-dialog
    content-class="round-dialog"
    overlay-opacity="0.7"
    max-width="400px"
    v-model="$store.state.main.openEntry"
  >
    <v-card
      min-height="170px"
      class="d-flex flex-column align-center pa-5"
      color="#1d1f20"
    >
      <p
        class="regularText text-center font-weight-medium pt-1 mb-5 mx-3"
        style="font-size: 1.2em"
      >
        Unlock
        <span class="font-weight-bold"> {{ game.title }}</span>
        and you can already solve it for at least
        {{ game.multiply * game.minPay }}$ (the minimum prize).
      </p>
      <pay-advertisment style="width: 90%"></pay-advertisment>
      <v-btn
        dark
        :disabled="isLoading"
        class="btnText rounded-lg"
        style="font-size: 0.9em; font-weight: 600; text-transform: none"
        :width="`${displaySize(55, -0.1)}%`"
        min-width="200px"
        color="#006f1f"
        small
        @click="payEntry"
        >Unlock for {{ game.entryPay }}€
        <span style="letter-spacing: 0.1px; font-size: 0.85em"
          >({{ (4.94 * game.entryPay).toFixed(2) }} lei)</span
        >
      </v-btn>
      <img
        height="55"
        width="40%"
        :src="require('@/assets/VivaLogo.svg')"
        class=""
      />
    </v-card>
  </v-dialog>
</template>

<script>
import PayAdvertisment from "@/components/PayAdvertisment.vue";

export default {
  components: { PayAdvertisment },
  data: () => ({
    solution: {},
    isLoading: false,
    paid: false,
  }),
  computed: {
    game() {
      return this.$store.getters["main/currentGame"];
    },
  },
  methods: {
    async payEntry() {
      this.isLoading = true;
      const path = `entryPay/${this.game._id}`;

      let orderCode = await this.$store.dispatch("makePayment", { path });
      if (orderCode) this.goExternApp(this.$vivaCheckout + orderCode);

      // this.$store.state.main.openEntry = false;
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
</style>
